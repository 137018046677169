import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

import Layout from '../../../components/Layout';
import { AuthUser } from '../../../components/AuthUser';
import { Searchbar } from '../../../components/Searchbar';
import { DetalleTracking } from './DetalleTracking';

import { RequestPage } from '../../../api/request';
import { getTrackingxVendedor, SearchTracking, SearchTrackingbyFechas, SearchTrackingbyCliente } from '../../../api/requestTracking';

import Pagination from 'react-responsive-pagination';
import { Dropdown, DropdownButton } from 'react-bootstrap'
import { AiOutlineDoubleLeft, AiOutlineDoubleRight, AiOutlineRight, AiOutlineLeft } from "react-icons/ai";
import { DateRangePicker, Stack } from 'rsuite';

import subDays from 'date-fns/subDays';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import addDays from 'date-fns/addDays';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';

export const ConsultaTracking = () => {

  // State del usuario
  const { user, getToken } = AuthUser();
  const navigate = useNavigate();

  // State del loading
  const [loading, setLoading] = useState(true);

  // States de filtros
  const [searchbarDoc, setSearchbarDoc] = useState('d-none')
  const [searchbarCli, setSearchbarCli] = useState('d-none')
  const [searchDate, setSearchDate] = useState('d-none')

  // States de busqueda
  const [notFound, setNotFound] = useState(false);
  const [searching, setSearching] = useState(false);
  const [found, setFound] = useState(false);
  const [foundByFecha, setFoundByFecha] = useState(false);
  const [foundByCliente, setFoundByCliente] = useState(false);
  const [trackingFound, setTrackingFound] = useState([]);
  const [trackingFoundFecha, setTrackingFoundFecha] = useState([]);
  const [trackingFoundCliente, setTrackingFoundCliente] = useState([]);

  // States de paginas
  const [page, setPage] = useState('');
  const [totalPages, setTotalPages] = useState('');
  const [urlPage, setUrlPage] = useState('');
  const [currentPage, setCurrentPage] = useState([]);

  // States para los datos
  const [Tracking, setTracking] = useState([]);

  const predefinedRanges = [
    {
      label: 'Últimos 3 meses',
      value: [startOfMonth(addMonths(new Date(), -3)), endOfMonth(addMonths(new Date(), -1))],
      placement: 'bottom'
    },
    {
      label: 'Últimos 6 meses',
      value: [startOfMonth(addMonths(new Date(), -6)), endOfMonth(addMonths(new Date(), -1))],
      placement: 'bottom'
    },
    {
      label: 'Último año',
      value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date(new Date().getFullYear(), 0, 0)],
      placement: 'bottom'
    }
  ];

  const { afterToday } = DateRangePicker;

  useEffect(() => {
    loadTracking();
  }, []);

  const loadTracking = async () => {
    setLoading(true);
    var res;
    if (user.CodVendedor !== null && user.CodSupervisor !== null && user.CodGerente === null) {
      res = await getTrackingxVendedor(user.CodVendedor);
    } else if (user.CodSupervisor === null || user.CodGerente !== null) {
      res = await getTrackingxVendedor('none');
    }

    setTracking(res.data)
    setPage(res.meta.current_page)
    setTotalPages(res.meta.last_page)
    setUrlPage(res.links.first)

    setLoading(false)
  }

  const loadPage = async (pageData) => {
    setLoading(true)

    const trackingData = await RequestPage(pageData);

    var arrTracking = []
    var data = {}

    for (let i = 0; i < trackingData.data.length; i++) {
      data = {
        Documento: trackingData.data[i].Documento,
        CodVendedor: trackingData.data[i].CodVendedor,
        NombreCliente: trackingData.data[i].NombreCliente,
        FechaCreacion: trackingData.data[i].FechaCreacion,
        FinDepositario: trackingData.data[i].FinDepositario,
        FinEmpacador: trackingData.data[i].FinEmpacador,
        Facturacion: trackingData.data[i].Facturacion,
        FechaEnvio: trackingData.data[i].FechaEnvio,
        FechaAnulacion: trackingData.data[i].FechaAnulacion,
        FechaSalida: trackingData.data[i].FechaSalida,
        Cajas: trackingData.data[i].Cajas,
        Bolsas: trackingData.data[i].Bolsas,
        Estado: trackingData.data[i].Estado,
      }
      arrTracking.push(data)
    }

    setPage(trackingData.meta.current_page)
    setTotalPages(trackingData.meta.last_page)
    setUrlPage(trackingData.links.first)

    setTracking(arrTracking)
    setLoading(false)
  }

  const handlePageChange = async (page) => {
    setCurrentPage(page)
    loadPage(urlPage.replace(/.$/, page))
  }

  const onSearch = async (busqueda) => {
    if (searchbarCli === 'd-block') {
      if (!busqueda) {
        setFound(false)
        setFoundByCliente(false)
        setFoundByFecha(false)
        return loadTracking();
      }
      setLoading(true);
      setNotFound(false);
      setSearching(true);
      const result = await SearchTrackingbyCliente(user.CodVendedor, busqueda);
      if (!result) {
        setNotFound(true);
        setLoading(false);
        setFound(false)
        setFoundByCliente(false)
        setFoundByFecha(false)
        return;
      } else {
        setFoundByCliente(true)
        setTrackingFoundCliente(result)
        setPage(1);
        setTotalPages(1);
      }
      setLoading(false);
      setSearching(false);
    }
    else if (searchbarDoc === 'd-block') {
      if (!busqueda) {
        setFound(false)
        return loadTracking();
      }
      setLoading(true);
      setNotFound(false);
      setSearching(true);
      const result = await SearchTracking(busqueda.toUpperCase());
      if (!result) {
        setNotFound(true);
        setLoading(false);
        setFound(false)
        return;
      } else {
        setFound(true)
        setTrackingFound(result.data)
        setPage(1);
        setTotalPages(1);
      }
      setLoading(false);
      setSearching(false);
    }
    else if (searchDate !== 'd-block') {
      setFoundByFecha(false)
    }
  };

  const changeDate = async (e) => {
    let date = JSON.stringify(e)
    let inicio = date.slice(2, 12) + " " + date.slice(14, 21)
    let fin = date.slice(29, 39) + " " + date.slice(41, 48)

    const res = await SearchTrackingbyFechas(user.CodVendedor, inicio, fin)
    if (res.length === 0) {
      setFound(false)
      setFoundByFecha(false)
    }
    else {
      setFound(false)
      setFoundByFecha(true)
      setTrackingFoundFecha(res)
    }
  }

  const showFilter = async (e, filtro) => {
    e.preventDefault();
    switch (filtro) {
      case 'Doc':
        setSearchbarDoc('d-block')
        setSearchbarCli('d-none')
        setSearchDate('d-none')
        break;
      case 'Cliente':
        setSearchbarDoc('d-none')
        setSearchbarCli('d-block')
        setSearchDate('d-none')
        break;
      case 'Fecha':
        setSearchbarDoc('d-none')
        setSearchbarCli('d-none')
        setSearchDate('d-block')
        break;

      case 'Limpiar':
        onSearch('')
        setSearchbarDoc('d-none')
        setSearchbarCli('d-none')
        setSearchDate('d-none')
        setFoundByFecha(false)
        onSearch('')
        break;

      default:
        onSearch('')
        setSearchbarDoc('d-none')
        setSearchbarCli('d-none')
        setSearchDate('d-none')
        break;
    }
  }

  function format(inputDate) {
    let date, month, year;

    date = inputDate.getDate();
    month = inputDate.getMonth() + 1;
    year = inputDate.getFullYear();

    date = date
      .toString()
      .padStart(2, '0');

    month = month
      .toString()
      .padStart(2, '0');

    return `${date}/${month}/${year}`;
  }

  return (
    <>
      <Layout>
        {/* Cuerpo de página */}
        {
          (loading) ?
            <>
              <div style={{ position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
                <span className="loader-tracking"></span>
              </div>
            </> :
            <>
              {/* Cabecera */}
              <div className="consultas-title pt-5">
                <h4 className='bg-belmeny text-light px-5 rounded-pill'>Tracking de Pedidos</h4>
              </div>

              <h6 className="text-center mt-2 mb-4 text-belmeny"><i>Vendedor - {user.Nombre} - {user.CodVendedor}</i></h6>
              {/* Desktop */}
              <div className="container-fluid d-md-block d-none mb-3">
                <div className="row">
                  <div className="col">
                    <div className="mb-2">
                      <DropdownButton
                        key={'end'}
                        id={`dropdown-button-drop-${'end'}`}
                        drop={'end'}
                        variant="primary"
                        title={` Filtros `}
                      >
                        <Dropdown.Item eventKey="1" className='text-decoration-none' onClick={e => showFilter(e, 'Doc')}>Por documento</Dropdown.Item>
                        <Dropdown.Item eventKey="2" className='text-decoration-none' onClick={e => showFilter(e, 'Cliente')}>Por cliente</Dropdown.Item>
                        <Dropdown.Item eventKey="3" className='text-decoration-none' onClick={e => showFilter(e, 'Fecha')}>Por fecha</Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item eventKey="4" className='bg-danger text-light text-decoration-none' onClick={e => showFilter(e, 'Limpiar')}>Limpiar filtros</Dropdown.Item>
                      </DropdownButton>
                    </div>
                    <div className={searchbarDoc}>
                      <Searchbar onSearch={onSearch} />
                    </div>
                    <div className={searchbarCli}>
                      <Searchbar onSearch={onSearch} />
                    </div>
                    <Stack direction="row" spacing={10} alignItems="flex-start" className={searchDate}>
                      <DateRangePicker
                        appearance={"subtle"}
                        ranges={predefinedRanges}
                        placeholder="Buscar por fecha"
                        style={{ width: 300 }}
                        onChange={e => changeDate(e)}
                        className="border rounded-pill mb-2"
                        showOneCalendar
                      />
                    </Stack>
                  </div>
                </div>
                <div className="rounded-0 my-2">
                  <table className='table table-bordered table-hover border-belmeny'>
                    <thead className='bg-belmeny text-light text-center'>
                      <tr>
                        <th>Pedido</th>
                        <th className='w-50'>Cliente</th>
                        <th>Fecha de ingreso al sistema</th>
                        <th>Estado</th>
                        <th style={{ width: "15%" }}>Opciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        (found) ?
                          <>
                            {/* Encontrado unicamente por barra de busqueda */}
                            {trackingFound.map((item) => <DetalleTracking item={item} found={found} />)}
                          </> :
                          (foundByFecha) ?
                            <>
                              {/* Encontrado por fechas */}
                              {trackingFoundFecha.map((item) => <DetalleTracking item={item} found={found} foundFecha={foundByFecha} />)}
                            </> :
                            (foundByCliente) ?
                              <>
                                {/* Encontrado por cliente */}
                                {trackingFoundCliente.map((item) => <DetalleTracking item={item} found={found} foundFecha={foundByFecha} foundCliente={foundByCliente} />)}
                              </> :
                              <>
                                {/* Carga normal de todos los pedidos */}
                                {Tracking.map((item) => <DetalleTracking item={item} found={found} />)}
                              </>
                      }
                    </tbody>
                  </table>
                </div>

                {
                  (foundByFecha) ?
                    <>
                      <Pagination
                        total={totalPages}
                        current={currentPage}
                        onPageChange={page => handlePageChange(page)}
                      />
                    </> :
                    <>
                      <Pagination
                        total={totalPages}
                        current={currentPage}
                        onPageChange={page => handlePageChange(page)}
                      />
                    </>
                }
              </div>

              {/* Mobile */}
              <div className="container-fluid d-block d-sm-none">
                <div className="row">
                  <div className="col">
                    <div className="mb-2">
                      <DropdownButton
                        key={'end'}
                        id={`dropdown-button-drop-${'end'}`}
                        drop={'end'}
                        variant="primary"
                        title={` Filtros `}
                      >
                        <Dropdown.Item eventKey="1" className='text-decoration-none' onClick={e => showFilter(e, 'Doc')}>Por documento</Dropdown.Item>
                        <Dropdown.Item eventKey="2" className='text-decoration-none' onClick={e => showFilter(e, 'Cliente')}>Por cliente</Dropdown.Item>
                        <Dropdown.Item eventKey="3" className='text-decoration-none' onClick={e => showFilter(e, 'Fecha')}>Por fecha</Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item eventKey="4" className='bg-danger text-light text-decoration-none' onClick={e => showFilter(e, 'Limpiar')}>Limpiar filtros</Dropdown.Item>
                      </DropdownButton>
                    </div>
                    <div className={searchbarDoc}>
                      <Searchbar onSearch={onSearch} />
                    </div>
                    <div className={searchbarCli}>
                      <Searchbar onSearch={onSearch} />
                    </div>
                    <Stack direction="row" spacing={10} alignItems="flex-start" className={searchDate}>
                      <DateRangePicker
                        appearance={"subtle"}
                        ranges={predefinedRanges}
                        placeholder="Buscar por fecha"
                        style={{ width: 300 }}
                        onChange={e => changeDate(e)}
                        className="border rounded-pill mb-2"
                        showOneCalendar
                      />
                    </Stack>
                  </div>
                </div>

                {
                  (found) ?
                    <>
                      {/* Encontrado unicamente por barra de busqueda */}
                      {trackingFound.map((item) => <DetalleTracking item={item} found={found} />)}
                    </> :
                    (foundByFecha) ?
                      <>
                        {/* Encontrado por fechas */}

                        {trackingFoundFecha.map((item) => <DetalleTracking item={item} found={found} foundFecha={foundByFecha} />)}

                      </> :
                      (foundByCliente) ?
                        <>
                          {/* Encontrado por cliente */}
                          {trackingFoundCliente.map((item) => <DetalleTracking item={item} found={found} foundFecha={foundByFecha} foundCliente={foundByCliente} />)}
                        </> :
                        <>
                          {/* Carga normal de todos los pedidos */}
                          {Tracking.map((item) => <DetalleTracking item={item} found={found} />)}
                        </>
                }

                {
                  (foundByFecha) ?
                    <>
                      <Pagination
                        total={totalPages}
                        current={currentPage}
                        onPageChange={page => handlePageChange(page)}
                      />
                    </> :
                    <>
                      <Pagination
                        total={totalPages}
                        current={currentPage}
                        onPageChange={page => handlePageChange(page)}
                      />
                    </>
                }
              </div>

              <div className="division bg-belmeny my-3 mx-auto" style={{ width: "95%" }}></div>
            </>
        }
      </Layout>
    </>
  )
}
